<template>
  <div class="create-page" v-if="ready">
    <div class="create-page__header">
      <CRow class="mr-0 ml-0">
        <CCol col="12" sm="6" class="d-flex align-items-center pl-0">
          <div class="zq-page-title-wrapper d-flex">
            <h3 class="mb-3 bread-content">{{ texts.createPage.titleAdvanced }}</h3>
            <IconWithTooltip class="zq--header-tooltip" :text="descriptions.pageTitle"/>
          </div>
        </CCol>
        <CCol col="12" sm="6">
          <ActionCreateBtns
            :currentStep="currentStep"
            :totalStep="totalStep"
            :finishAction="createEntity"
            @updateCurrentStep="updateCurrentStep"
            @next-step="nextStep"
          />
        </CCol>
      </CRow>
      <WizardHeader
        :currentStep="currentStep"
        :steps="steps"
        :isCompleted="isCompleted"
        @updateCurrentStep="updateCurrentStep"
      />
    </div>
    <div class="content">
      <FormBuilder
        v-if="currentStep === 0"
        :list="formList"
        @updated="updateSettingsData"
        :page="{ title: texts.createPage.title, info: descriptions }"
        :isCreateHeader="false"
        :isSettingsStep="true"
      />
      <CreateCompetitionScheduling
        ref="createCompetitionScheduling"
        v-if="steps.find(step => step.key === 'scheduling') && currentStep === getStepNumber('scheduling')"
        @updateSchedulingData="updateSchedulingData"
        @resetValidate="resetSchedulingValidate"
        @updateConstraints="getConstraints"
        creationType="advanced"
        :schedulingData="schedulingData"
        :constraintsData="constraints"
        :descriptions="schedulingDescriptions"
        :name="settingsData.name"
        :isFrom="schedulingDataValid_startDate"
        :isScheduleType="schedulingDataValid_scheduleType"
        :isEvery="schedulingDataValid_every"
        :isLimit="schedulingDataValid_scheduleOccurrencesLimit"
      />
      <CreateEntrants
        v-if="steps.find(step => step.key === 'entrants') && currentStep === getStepNumber('entrants')"
        :entrantsData="entrantsData"
        :constraintsData="constraints"
        :descriptions="entrantDescriptions"
        @updateEntrantsData="updateEntrantsData"
        @updateLimitParticipants="updateLimitParticipants"
        @updateCanParticipate="updateCanParticipate"
        @updateConstraints="getConstraints"
        @updateOptIn="updateOptIn"
      />
      <CreateContest
        v-if="steps.find(step => step.key === 'contests') && currentStep === getStepNumber('contests')"
        :isCreateCompetition="true"
        @updateContestData="updateContestData"
      />
      <AddProducts
        v-if="steps.find(step => step.key === 'productIds') && currentStep === getStepNumber('productIds') - 2"
        :productsData="productsData"
        :isAdvanced="true"
        :descriptions="productsDescriptions"
        :stepNumber="getStepNumber('productIds') - 1"
        @updateProductsData="updateProductsData"
        @updateIsAllProductsInclude="updateIsAllProductsInclude"
        @updateCurrentTypes="updateProductsCurrentTypes"
      />
      <CreateTranslations
        v-if="steps.find(step => step.key === 'translations') && currentStep === getStepNumber('translations') - 2"
        :entityData="settingsData"
        :translatableFields="translatableFields"
        :translationsData="translationsData"
        @updated="updateTranslationsData"
        :stepNumber="getStepNumber('translations') - 1"
      />
      <CreateRewards
        v-if="steps.find(step => step.key === 'rewards') && currentStep === getStepNumber('rewards')"
        @updateRewardData="updateRewardData"
        :rewardsData="rewardsData"
        :entityType="'Competition'"
        :pageNumber="4"
      />
      <CompetitionSummaryStep
        v-if="currentStep === getStepNumber('summary')"
        :settingsData="settingsData"
        :dependantOnData="dependantOnData"
        :schedulingData="schedulingData"
        :rewardsData="rewardsData"
        :translationsData="translationsData"
        :productsData="productsData"
        :entrantsData="entrantsData"
        :stepNumber="getStepNumber('summary') + 1"
        :model="model"
        :translatableFields="translatableFields"
        :showEmptyRewardsData="false"
      />
    </div>
  </div>
  <PreviewSpiner
    v-else
    :texts="['Validating...', 'Preparing...', 'Redirecting...']"
  />
  <!-- /Multi Step Page -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import ActionCreateBtns from '@/shared/components/steps/ActionCreateBtns';
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import WizardHeader from '@/shared/components/steps/Header';
import CreateTranslations from '@/shared/components/supportModels/translations/CreateTranslations';
import CreateCompetitionScheduling from '@/shared/components/supportModels/scheduling/CreateCompetitionScheduling';
import CreateEntrants from '@/shared/components/supportModels/entrants/CreateEntrants';
import AddProducts from '@/shared/components/supportModels/products/AddProducts';
import CreateRewards from '@/shared/components/supportModels/rewards/AddRewards';
import CompetitionSummaryStep from '@/shared/components/steps/CompetitionSummaryStep';
import CreateContest from '@/views/ziqni/competitions/create/contest';
import fieldHelpers from '@/utils/ZiqniFieldHelper';
import { translationsTransform } from '@/utils/translationsUtils';
import { stepSubTitles } from '@/config/pageTexts/stepSubTitles.json';
import { competitions } from '@/config/descriptions/competitions.json';
import { competitionsTexts } from '@/config/pageTexts/competitions.json';
import competitionFields from '@/generated/ziqni/store/modules/competitions/fields';
import createAdvancedFields from '@/generated/ziqni/fields/CreateCompetitionRequestZq';
import { cloneDeep } from 'lodash';
import { competitionQuery } from '@/helpers/rules/competitionQuery';
import PreviewSpiner from "@/shared/UI/Spiner";

export default {
  name: 'CreateCompetition',
  components: {
    ActionCreateBtns,
    IconWithTooltip,
    WizardHeader,
    CreateTranslations,
    CreateCompetitionScheduling,
    CreateEntrants,
    AddProducts,
    CreateRewards,
    CreateContest,
    CompetitionSummaryStep,
    PreviewSpiner,
  },
  data() {
    return {
      model: 'competitions',
      ready: true,
      currentStep: 0,
      totalStep: 0,
      descriptions: {
        ...competitions.create,
      },
      texts: {
        ...competitionsTexts,
      },
      steps: [],
      stepKeys: [],
      firstStep: {
        title: 'Settings',
        subTitle: stepSubTitles.settings,
        step: 0,
      },
      lastStep: {
        title: 'Summary',
        subTitle: stepSubTitles.summary,
        key: 'summary',
        step: 2,
      },
      formList: [],
      settingsData: {},
      translationsData: {},
      translationsMap: {},
      requiredFields: [],
      translatableFields: [],
      dependantOnData: {
        currentTypes: {},
        formData: {
          shouldMatchAtLeast: null,
          dependantOn: {
            must: [],
            mustNot: [],
            should: []
          }
        },
        selectedData: []
      },
      dependantOnDataValidate: null,
      schedulingData: {
        autoStart: true,
        autoStop: true,
        defaultRanking: true,
        scoreDesc: false,
        scoreFirst: false,
        ignoreTime: false,
        ignoreScore: false,
        timeDesc: false,
        scheduledStartDate: new Date(),
        scheduledEndDate: '',
        strategies: {
          strategyType: "",
          rankingStrategy: {
            constraints: []
          },
          scoringStrategy: {
            limitUpdatesTo: 0,
            sumBestXOf: 0,
            lastUpdateTimeStamp: 0,
            recordTimeWhenSumReaches: 0
          }
        },
      },
      entrantsData: {
        currentTypes: {},
        selectedData: [],
        limitParticipants: false,
        canParticipate: true,
        optinRequiredForEntrants: false,
        formData: {
          maxNumberOfEntrants: null,
          minNumberOfEntrants: null,
          shouldMatchAtLeast: 1,
          must: [],
          mustNot: [],
          should: []
        }
      },
      productsData: {
        currentTypes: {},
        isAllProductsInclude: true,
        selectedData: [],
        formData: {
          shouldMatchAtLeast: 1,
          dependantOn: {
            must: [],
            mustNot: [],
            should: []
          }
        }
      },
      contestData: {},
      constraints: [],
      schedulingDataValid_startDate: null,
      schedulingDataValid_every: null,
      schedulingDataValid_scheduleOccurrencesLimit: null,
      schedulingDataValid_scheduleType: null,
      rewardsData: [],
      transformedRewards: [],
      productIds: [],
      rulesQueryData: {},
      isCompleted: false,
      stepsOrder: {
        scheduling: 1,
        entrants: 2,
        rewards: 3,
        rules: 4,
        productIds: 5,
        translations: 6,
      },
      filterFormList: ['minNumberOfEntrants', 'maxNumberOfEntrants', 'competitionType', 'entrantMemberType' , 'strategies'],
      filterFormSteps: ['productTagsFilter', 'entrantMemberTagsFilter', 'contests', 'strategies'],
    };
  },
  computed: {
    ...mapGetters('competitions', ['message', 'loading']),
    isButtonDisabled() {
      if (this.loading) return true;
      return !!this.message;
    },
    schedulingDescriptions() {
      return {
        ...competitions.create.multi.scheduling,
      }
    },
    entrantDescriptions() {
      return {
        ...competitions.create.multi.entrants,
      }
    },
    productsDescriptions() {
      return {
        ...competitions.create.multi.products,
      }
    }
  },
  provide() {
    return {
      stepKeys: this.stepKeys,
      model: this.model,
    }
  },
  watch: {
    message(val) {
      if (val === this.texts.createPage.duplicateMessage) {
        this.idValidate = false;
        this.invalidRefIdFeedback = null;
      } else if (val === this.texts.createPage.emptyMessage) {
        this.idValidate = false;
        this.invalidRefIdFeedback = null;
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions(
      'competitions',
      [
        'handleCreateCompetitions',
        'handleGetCompetitionsToClone',
      ]
    ),
    ...mapActions('products', ['handleGetProducts']),
    ...mapActions('tags', ['handleGetTags', 'handleGetTagsByQuery']),
    ...mapActions('rewardTypes', ['handleGetRewardTypes']),
    ...mapActions('languages', ['handleGetLanguages']),
    initialize() {
      routerBreadcrumbs(this.$router.currentRoute);

      competitionFields.createFields = createAdvancedFields;

      // Remove Group stages
      delete competitionFields.createFields.fields.numberOfGroupStages

      this.formList = fieldHelpers.prepareCreateFormList(
        competitionFields,
        competitionsTexts.createPage,
        competitions.create
      );

      this.formList = this.formList.filter(item => !this.filterFormList.includes(item.key));

      let formSteps = [];

      this.formList.forEach(field => {
        if (field.type.indexOf('_OBJECT') !== -1) {
          formSteps.push(field);
        }
        if (field.required) {
          this.requiredFields.push(field.key)
        }
      });

      formSteps = formSteps.filter(step => !this.filterFormSteps.includes(step.key));

      if (formSteps.length) {
        let objectTypes = [];
        formSteps.forEach(step => {
          objectTypes.push(step.type)
          if (step.key === 'scheduling') {
            this.requiredFields.scheduling = [];
          }
        });
        this.formList = this.formList.filter(formItem => {
          return !objectTypes.includes(formItem.type)
        });

        this.steps.push(this.firstStep);

        if (this.model === 'competitions') {
          this.formList = this.formList.filter(formItem => {
            return formItem.key !== 'scheduledStartDate' && formItem.key !== 'scheduledEndDate';
          });

          let schedulingStep = {
            label: 'Scheduling',
            key: 'scheduling'
          };
          formSteps.splice(0, 0, schedulingStep);

          let entrantsStep = {
            label: 'Entrants',
            key: 'entrants'
          };
          formSteps.splice(1, 0, entrantsStep);

          formSteps = formSteps.filter(step => step.key !== 'dependantOn')

          const rewardsIdx = formSteps.findIndex(item => item.key === 'rewards')
          formSteps.splice(rewardsIdx, 1);

          const rulesIdx = formSteps.findIndex(item => item.key === 'rules')
          formSteps.splice(rulesIdx, 1);

          let productsIdx = formSteps.findIndex(step => step.key === 'productIds');
          if (productsIdx !== -1) {
            formSteps[productsIdx].label = 'products';
          }

          this.requiredFields.scheduling = [];
          this.requiredFields.scheduling.push('scheduledStartDate');
          this.requiredFields.scheduling.push('scheduledEndDate');
        }

        formSteps.forEach(step => {
          this.steps.push({
            title: step.label,
            subTitle: stepSubTitles[step.key],
            key: step.key,
            step: this.stepsOrder[step.key],
          });
          this.stepKeys.push(step.key);
        })

        this.steps.sort((a, b) => a.step > b.step ? 1 : -1)

        this.translatableFields = competitionFields.baseFields.translatableFields;

        this.lastStep.step = this.steps.length;
        this.steps.push(this.lastStep);
        this.totalStep = formSteps.length + 1;
      }

      if (this.$route.query.cloneId) {
        this.ready = false;
        this.getCloneData(this.$route.query.cloneId);
      }
    },
    async getCloneData(cloneId) {
      const competitionsToClone = await this.handleGetCompetitionsToClone({id: cloneId});
      if (competitionsToClone && competitionsToClone.length) {
        this.ready = true;

        const competitionToClone = competitionsToClone[0];
        await this.setCloneData(competitionToClone);
      }

    },
    async setCloneData(competitionData) {
      const completedFormList = fieldHelpers.prepareEditFormList(
        competitionFields,
        this.texts.editPage,
        this.descriptions,
        competitionData
      );

      const availableFormFields = this.formList.map(item => item.key);
      this.formList = completedFormList.filter(item => availableFormFields.includes(item.key));

      const { scheduledStartDate, scheduledEndDate } = competitionData;
      this.schedulingData.scheduledStartDate = scheduledStartDate;
      this.schedulingData.scheduledEndDate = scheduledEndDate;

      if (competitionData.productIds.length) {
        this.productsData.selectedData = await this.handleGetProducts({idArray: competitionData.productIds});
        this.productsData.isAllProductsInclude = false;
      } else if (competitionData.productTagsFilter && Object.keys(competitionData.productTagsFilter).length) {
        const keyArray = Object.values(competitionData.productTagsFilter)
          .flat()
          .filter(e => typeof e === 'string' && e !== '');

        this.productsData.selectedData = await this.handleGetTagsByQuery({
          queryRequest: {
            must: [
              {
                queryField: 'key',
                queryValues: keyArray
              }
            ],
            limit: keyArray.length
          }
        });

        this.productsData.isAllProductsInclude = false;
        this.productsData.formData.shouldMatchAtLeast = competitionData.productTagsFilter.shouldMatchAtLeast;
        this.productsData.formData.dependantOn.must = competitionData.productTagsFilter.must;
        this.productsData.formData.dependantOn.mustNot = competitionData.productTagsFilter.mustNot;
        this.productsData.formData.dependantOn.should = competitionData.productTagsFilter.should;
      }

      if (competitionData.rewards.length) {
        const cloneRewards = cloneDeep(competitionData.rewards);
        const rewardTypeIds = cloneRewards.map(reward => reward.rewardTypeId);
        const rewardTypes = await this.handleGetRewardTypes({idArray: rewardTypeIds});
        cloneRewards.forEach(reward => {
          reward.rewardType = rewardTypes.find(rewardType => rewardType.id === reward.rewardTypeId);
        })
        this.updateRewardData(cloneRewards);
      }

      // Entrants
      if (competitionData.entrantMemberTagsFilter) {
        const keyArray = Object.values(competitionData.entrantMemberTagsFilter)
            .flat()
            .filter(e => typeof e === 'string' && e !== '');

        const data = await this.handleGetTagsByQuery({
          queryRequest: {
            must: [
              {
                queryField: 'key',
                queryValues: keyArray
              }
            ],
            limit: keyArray.length
          }
        });

        const selectedData = data.map(item => ({...item, valid: true}))

        const canParticipate = !keyArray.length;

        const must = this.getCurrentTypeValue(competitionData.entrantMemberTagsFilter.must, 'must');
        const mustNot = this.getCurrentTypeValue(competitionData.entrantMemberTagsFilter.mustNot, 'mustNot');
        const should = this.getCurrentTypeValue(competitionData.entrantMemberTagsFilter.should, 'should');

        const currentTypes = {...must, ...should, ...mustNot};

        const formData = {
          maxNumberOfEntrants: competitionData.maxNumberOfEntrants ?? null,
          minNumberOfEntrants: competitionData.minNumberOfEntrants ?? null,
          must: competitionData.entrantMemberTagsFilter.must ?? [],
          mustNot: competitionData.entrantMemberTagsFilter.mustNot ?? [],
          should: competitionData.entrantMemberTagsFilter.should ?? [],
          shouldMatchAtLeast: competitionData.entrantMemberTagsFilter.shouldMatchAtLeast
        };
        const limitParticipants = false;

        const optinRequiredForEntrants = competitionData.constraints.includes('optinRequiredForEntrants');

        this.updateEntrantsData({canParticipate, selectedData, currentTypes, formData, limitParticipants, optinRequiredForEntrants})
      }

      // Translations
      if (competitionData.translations) {
        const languages = await this.handleGetLanguages({idArray: []});
        const map = this.getMapLanguages(languages)

        const achTranslations = competitionData.translations.map(item => {
          const translations = item.translations.reduce((acc, translation) => {
            return {...acc, [translation.fieldName]: translation.text}
          }, {})
          return {languageKey: item.languageKey, translations: translations}
        }).reduce((obj, item) => {
          obj[item['languageKey']] = item.translations
          return obj
        }, {});

        const val = languages.reduce((acc, lang) => {
          const language = achTranslations[lang.key]
              ? {[lang.name]: achTranslations[lang.key]}
              : {[lang.name]: {}}
          return {...acc, ...language}
        }, {})

        this.updateTranslationsData({map, val})
      }

    },
    getMapLanguages(languages) {
      return languages.reduce((acc, language) => {
        const lang = {[language.name]: language.key}
        return {...acc,  ...lang};
      }, {})
    },
    getCurrentTypeValue(arr, typeValue) {
      if (!arr) return;

      return arr.reduce((accumulator, value) => {
        return {...accumulator, [value]: typeValue};
      }, {});
    },
    updateCurrentStep(val) {
      this.currentStep = val;
    },
    nextStep() {
      let invalidFields = this.getInvalidFields(true);
      if (!invalidFields.length) {
        this.currentStep += 1;
      } else {
        this.setInvalidFields(invalidFields);
      }
      if (this.currentStep === this.getStepNumber('summary')) {
        this.isCompleted = true;
      }
    },
    getInvalidFields() {
      let result = [];
      //TODO: remove after implementing constraint
      this.settingsData.constraints = [];

      for (let key in this.settingsData) {
        if (this.requiredFields.includes(key) && (this.settingsData[key] === null || this.settingsData[key] === '')) {
          result.push(key);
        }
      }
      if (this.getStepNumber('scheduling') === this.currentStep) {
        for (let key in this.schedulingData) {
          const endDate = new Date(this.schedulingData.scheduledEndDate);
          const currentDate = new Date();
          if (this.schedulingData.scheduledEndDate && endDate <= currentDate && this.$route.query.cloneId) {
            result.push('scheduledEndDate');
            this.$refs.createCompetitionScheduling.setInvalidEndDate()
          }
          if (this.requiredFields.scheduling.includes(key) && (this.schedulingData[key] === null || this.schedulingData[key] === '')) {
            result.push(key);
          }
        }
      }
      if (this.getStepNumber('entrants') === this.currentStep) {
        if (!this.entrantsData.canParticipate) {
          const tagsFiltersLength = this.entrantsData.formData.must.length
            + this.entrantsData.formData.mustNot.length
            + this.entrantsData.formData.should.length;
          if (!tagsFiltersLength) {
            result.push('canParticipate');
          }
        }
      }
      if (this.getStepNumber('productIds') === this.currentStep) {
        if (!this.productsData.isAllProductsInclude) {
          const tagsFiltersLength = this.productsData.formData.dependantOn.must.length
            + this.productsData.formData.dependantOn.mustNot.length
            + this.productsData.formData.dependantOn.should.length;
          if (!tagsFiltersLength && !this.productsData.selectedData.length) {
            result.push('individualProducts');
            result.push('productGroups');
          }
        }
      }

      return result;
    },
    getStepNumber(key) {
      let entityObject = this.steps.find(step => step.key === key);
      if (entityObject !== undefined && entityObject.hasOwnProperty('step')) {
        return entityObject.step;
      } else {
        return -1;
      }
    },
    updateSettingsData(val) {
      this.settingsData = val;
    },
    updateDependantOnData(val) {
      this.dependantOnData = val;
    },
    resetDependantValidation() {
      this.dependantOnDataValidate = null;
    },
    updateDependantOnCurrentTypes(val) {
      this.dependantOnData.currentTypes = val;
    },
    updateSchedulingData(val) {
      this.schedulingData = val;
    },
    updateEntrantsData(val) {
      this.entrantsData = val;
    },
    updateContestData(val) {
      this.contestData = val;
      this.nextStep();
    },
    updateLimitParticipants(val) {
      this.entrantsData.limitParticipants = val;
    },
    updateCanParticipate(val) {
      this.entrantsData.canParticipate = val;
    },
    updateOptIn(val) {
      this.entrantsData.optinRequiredForEntrants = val;
    },
    updateProductsData(val) {
      this.productsData = val;
    },
    updateIsAllProductsInclude(val) {
      this.productsData.isAllProductsInclude = val;
    },
    updateProductsCurrentTypes(val) {
      this.productsData.currentTypes = val;
    },
    resetSchedulingValidate() {
      this.schedulingDataValid_startDate = null;
      this.schedulingDataValid_every = null;
      this.schedulingDataValid_scheduleOccurrencesLimit = null;
      this.schedulingDataValid_scheduleType = null;
    },
    updateTranslationsData(obj) {
      this.translationsMap = obj.map;
      this.translationsData = obj.val;
    },
    updateRewardData(rewards) {
      this.rewardsData = rewards;
      let localRewards = cloneDeep(rewards);
      let transformedRewards = [];
      if (localRewards.length) {
        localRewards.forEach(reward => {
          reward.rewardTypeId = reward.rewardType.id;
          delete reward.rewardType;
          delete reward.id;
          delete reward.spaceName;
          delete reward.created;
          delete reward.entityType;
          delete reward.entityId;
          delete reward.translatableFields;
          transformedRewards.push(reward);
        })
      }
      this.transformedRewards = transformedRewards;
    },
    setInvalidFields(invalidFields) {
      invalidFields.forEach(invalidField => {
        let invalidFieldElement = document.getElementsByName(invalidField)[0];
        let invalidElement = invalidFieldElement;
        if (
          !invalidFieldElement.classList.contains('zq--form-row')
          && !invalidFieldElement.classList.contains('zq--data-picker')
        ) {
          invalidElement = invalidFieldElement.parentNode;
        }
        invalidElement.classList.add('zq-invalid');
      })
    },
    getConstraints(val) {
      this.constraints = val;
    },
    getEntrantMemberTagsFilter(formData) {
      return {
        must: formData.must.length ? formData.must : null,
        mustNot: formData.mustNot.length ? formData.mustNot : null,
        should: formData.should.length ? formData.should : null,
        shouldMatchAtLeast: formData.shouldMatchAtLeast,
      }
    },
    getProductTagsFilterTagsFilter() {
      return {
        must: this.productsData.formData.dependantOn.must.length ? this.productsData.formData.dependantOn.must : [],
        mustNot: this.productsData.formData.dependantOn.mustNot.length ? this.productsData.formData.dependantOn.mustNot : [],
        should: this.productsData.formData.dependantOn.should.length ? this.productsData.formData.dependantOn.should : [],
        shouldMatchAtLeast: this.productsData.formData.dependantOn.shouldMatchAtLeast,
      }
    },
    async getProductIds() {
      let productIds = [];

      if (this.productsData.isAllProductsInclude) {
        let products = await this.handleGetProducts({idArray: []});
        products.forEach(item => {
          productIds.push(item.id);
        });
      } else {
        if (this.productsData.selectedData.length && Object.hasOwn(this.productsData.selectedData[0], 'productRefId')) {
          this.productsData.selectedData.forEach(product => {
            productIds.push(product.id);
          })
        }
      }

      return productIds;
    },
    async createEntity() {
      let formData = {};

      if (Object.keys(this.settingsData).length) {
        delete this.settingsData.constraints;
        formData = {...this.settingsData};
      }

      formData.addConstraints = [];

      if (Object.keys(this.translationsData).length) {
        formData.translations = translationsTransform(this.translationsData, this.translationsMap);
      }
      if (this.transformedRewards.length) {
        formData.rewards = this.transformedRewards;
      }
      if (Object.keys(this.schedulingData).length && this.schedulingData.scheduleType) {
        formData.scheduling = this.schedulingData;
      }
      if (formData.key && typeof formData.key === 'object') {
        formData.name = formData.key.name;
        formData.key = formData.key.key;
      }

      formData.scheduledStartDate = this.schedulingData.scheduledStartDate;
      formData.scheduledEndDate = this.schedulingData.scheduledEndDate;
      if (this.schedulingData.autoStart) {
        formData.addConstraints.push('autoStart');
      }
      if (this.schedulingData.autoStop) {
        formData.addConstraints.push('autoStop');
      }
      if (this.entrantsData.optinRequiredForEntrants) {
        formData.addConstraints.push('optinRequiredForEntrants');
      }

      if (this.productsData.isAllProductsInclude) {
        formData.addConstraints.push('allProducts');
        formData.productIds = null;
        formData.productTagsFilter = null;
      } else {
        formData.productTagsFilter = null;
        formData.productIds = await this.getProductIds();
      }
      formData.numberOfGroupStages = Number(formData.numberOfGroupStages) ?? 0;
      formData.tags = formData.tags ?? [];
      formData.numberOfRounds = Number(formData.numberOfRounds);
      formData.minNumberOfEntrants = this.entrantsData.formData.minNumberOfEntrants ?? 0;
      formData.maxNumberOfEntrants = this.entrantsData.formData.maxNumberOfEntrants;
      formData.entrantMemberType = formData.entrantMemberType ?? 'Individual';
      formData.entrantMemberTagsFilter = this.getEntrantMemberTagsFilter(this.entrantsData.formData);
      if (formData.productIds && !formData.productIds.length) {
        formData.productTagsFilter = this.getProductTagsFilterTagsFilter();
      }
      formData.competitionType = 'Leaderboard';

      if (Object.keys(this.contestData).length) {
        delete this.contestData.round;
        delete this.contestData.row;
        this.contestData.maxNumberOfEntrants = this.contestData.maxNumberOfEntrants ?? '9999'
        formData.contests = this.contestData;
      }

      this.ready = false;

      this.handleCreateCompetitions({createCompetitionRequest: JSON.parse(JSON.stringify(formData))})
        .then(data => {
          if (data.length) {
            if (this.$route.query.cloneId) {
              this.$router.push({
                name: 'PreviewCompetition',
                params: {
                  id: data[0].id,
                  showMessage: false,
                },
                query: {
                  cloneId: this.$route.query.cloneId
                }
              })
            } else {
              this.$router.push({
                name: 'PreviewCompetition',
                params: {
                  id: data[0].id,
                  showMessage: true,
                }
              })
            }
          } else {
            this.ready = true;
            console.log('Something went wrong');
          }
        });
    },
  },
};
</script>

<style lang="scss">
.create-page {
  &__header {
    background-color: var(--zq-main-bg);
  }
  .zq-invalid {
    .form-control {
      border: solid 1px var(--zq-warn);
    }
  }
}
</style>
